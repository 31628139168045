<template>
  <div class="form">
    <div>
      <img src="../assets/banner/messagemail.png" />
      <span class="topfont">
        &nbsp;首次登录，请绑定手机号，方便您即时了解订单进度</span
      >
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <div>
        <el-form-item class="inputplus" prop="phoneNumber">
          <el-input
            class="from-style1"
            placeholder="请输入手机号"
            v-model.number="ruleForm.phoneNumber"
          />
        </el-form-item>
      </div>
      <div class="from-style2">
        <div>
          <el-form-item class="inputplus" prop="validateCode">
            <el-input
              class="from-style1"
              placeholder="请输入验证码"
              v-model.number="ruleForm.validateCode"
              autocomplete="off"
              maxlength="6"
              id="autofouce"
            />
          </el-form-item>
          <el-button
            v-if="isCheck"
            class="code"
            :class="{ disabled: isActive }"
            :disabled="isActive"
            @click="getCode"
          >
            {{ isActive ? `剩余${count}秒` : "发送验证码" }}
          </el-button>
          <el-button v-else @click="isrefsend" class="code">重新发送</el-button>
        </div>
      </div>
      <el-button class="btn" type="submit" @click="submitForm(ruleFormRef)"
        >立即登录</el-button
      >
      <div class="form-item2">
        <div class="agree">
          <!-- <el-checkbox
            @change="checkIS"
            style="position: relative; top: 2px; right: 3px"
            v-model="checked"
            size="large"
          /> -->
          <el-image style="margin-right: 8px;position: relative; top: 4px;" v-if="!checked" @click="checkIS(true)" v-model="checked" :src="require('./img/isticked.png')" />
          <el-image style="margin-right: 8px;position: relative; top: 4px;" v-else @click="checkIS(false)" v-model="checked" :src="require('./img/tickedActice.png')" />
          <span style="color:#AAAAAA">我已阅读并同意</span>
          <a style="color:#333333" href="javascript:"><router-link :to="`/putsomeing?page=1`" target="_blank">《用户协议》</router-link></a>
          <span style="color:#AAAAAA">和</span>
          <a style="color:#333333" href="javascript:"><router-link :to="`/putsomeing?page=2`" target="_blank">《隐私协议》</router-link></a>
        </div>
        <div v-show="showtime" style="color: var(--el-color-danger)">
          <i>请阅读并同意《用户协议》《隐私协议》</i>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script setup>
import { ref, onMounted, onUpdated, reactive } from "vue";
import { FormInstance, FormRules , ElMessage} from "element-plus";
import { getPhoneCode } from "@/api/login/index";
const isCheck = ref(true);
const ruleFormRef = ref();
const isActive = ref(false);
const checked = ref(false);
const showtime = ref(false);
const validataOk = ref(false);
const count = ref(60);
const uuid = ref();

const isrefsend = () => {
  isCheck.value = true;
  getCode();
};
const checkIS = (res) => {
  if (res) {
    showtime.value = false;
    checked.value = true
  } else {
    showtime.value = true;
    checked.value = false
  }
};
const sendPhoneCode = () => {
  let data = {
    phoneNumber: ruleForm.phoneNumber,
  };
  ifpassEnter.value = 2
  getPhoneCode(data).then((res) => {
    if (res.code == 200) {
      ifpassEnter.value = 1
      uuid.value = res.data
      isActive.value = true;
      var timer = setInterval(function () {
        count.value--;
        if (count.value == 0) {
          clearInterval(timer);
          isCheck.value = false;
          count.value = 60;
        }
      }, 1000);
    }else{
      ElMessage({
        message: res.message,
        type: 'error',
      })
      document.getElementById("autofouce").focus();
      ifpassEnter.value = 1
    }
  });
};
const ifpassEnter = ref(1)
const getCode = () => {
  if (validataOk.value) {
    var telreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (
      !telreg.test(ruleForm.phoneNumber) ||
      ruleForm.phoneNumber.toString().length != 11
    ) {
      return { data: false, msg: "手机号码格式不正确" };
    } else {
      if(ifpassEnter.value == 1){
        sendPhoneCode()
      }
    }
  }else{
    ElMessage.error('请输入正确的手机号')
  }
};
const phoneNumber = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入手机号!"));
  }
  if (!Number.isInteger(value)) {
    callback(new Error("请输入数字!"));
  } else {
    if (value.toString().length < 11) {
      callback(new Error("请输入11位数手机号!"));
    } else {
      validataOk.value = true;
      callback();
    }
  }
};
const validateCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请输入验证码!"));
  } else {
    if (value.toString().length < 6) {
      callback(new Error("请输入6位数验证码!"));
    } else {
      callback();
    }
  }
};

const ruleForm = reactive({
  phoneNumber: "",
  validateCode: "",
});
const rules = reactive({
  phoneNumber: [
    { validator: phoneNumber, trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  validateCode: [{ validator: validateCode, trigger: "blur" }],
});

const emit = defineEmits(["phoneData"]);
const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      if (checked.value) {
        showtime.value = false;
        emit("phoneData", ruleForm.phoneNumber, ruleForm.validateCode,uuid.value);
      } else {
        showtime.value = true;
      }
    } else {
      console.log("error submit!");
      return false;
    }
  });
};
</script>
<style scoped lang="less">
::v-deep .el-input__validateIcon {
  display: none;
}
.loginIndexImg {
  margin-left: 100px;
  text-align: center;
  .headerImg {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-bottom: 16px;
    margin-right: 5px;
  }
}
.loginIndex {
  margin-left: 122px;
  text-align: center;
  img {
    width: 12px;
    height: 14px;
    margin-bottom: 20px;
  }
}
// .Order {
//   margin-left: 20px;
// }
::v-deep .el-menu--horizontal {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0 !important;
  border-right: none;
}
.el-menu-item:hover {
  /* background-color: #ce1200 !important; */
  background: rgba(#ce1200, 0.6) !important;
}
/* 列表 ul 的样式  */
.nav1 {
  width: 1000px;
  margin: 0 auto; /* 整个列表居中显示 */
}

ul {
  list-style: none; /* 去除列表默认符号（圆点） */
}

/* 列表项 li 的样式  */
.nav1 .item {
  float: left; /* 设置浮动，列表项横向排列 */
  width: 120px;
  text-align: center; /* 文本水平居中 */
  color: white;
  line-height: 40px; /* 设置行高，文本垂直居中 */
}

/* 伪类选择器，鼠标移入时，对应列表项改变样式 */
.nav1 .item:hover {
  background-color: red;
}

/* 列表项 li 中子导航栏的样式  */
.nav1 .item ul {
  display: none; /* 元素不显示，隐藏子导航栏 */
  background-color: #004b8c; 
}

/* 鼠标移入，对应列表项中子导航栏改变样式  */
.nav1 .item:hover ul {
  display: block; /* 元素显示为块元素，子导航栏出现 */
}

/* 鼠标移入子导航栏的列表项时，列表项改变样式  */
.nav1 .item ul li:hover {
  background-color: red;
}
.common-layout {
  /* // position: relative;
      // top: 0;
      // left: 0;
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
      // transition: all 0.2s ease-in-out; */
  background-color: transparent;
  border-bottom: none;
  -webkit-transition: background 0.5s 0.3s;
  transition: background 0.5s 0.3s;
  color: #fff;
}
::v-deep .el-header {
  --el-header-padding: 0 0 !important;
  --el-header-height: 60px;
  padding: var(--el-header-padding);
  box-sizing: border-box;
  flex-shrink: 0;
  height: var(--el-header-height);
  --el-menu-bg-color: none;
}
::v-deep .el-menu--horizonta ul {
  background-color: #ce1200;
}

.toplett {
  line-height: 60px;
  height: 60px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.containertop {
  background: rgba(#000, 0.29);
}

/* .loginstyle {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      width: 450px;
      height: 480px;
      background: #ffffff;
      border-radius: 20px;
    } */

.form {
  padding: 0 20px;
  .topfont {
    width: 391px;
    height: 18px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .form-item :first-child {
    margin-top: 20px;
  }

  &-item {
    margin-bottom: 18px;
    .input {
      position: relative;
      width: 476px;
      height: 44px;
      border-radius: 5px;
      top: -40px;
      left: -80px;
      > i {
        width: 34px;
        height: 34px;
        background: #cfcdcd;
        color: #fff;
        position: absolute;
        left: 1px;
        top: 1px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
      }
      input {
        padding-left: 16px;
        border: 1px solid #cfcdcd;
        height: 36px;
        border-radius: 5px;
        line-height: 36px;
        width: 100%;
        &.error {
          border-color: @priceColor;
        }
        &.active,
        &:focus {
          border-color: @xtxColor;
        }
      }

      .code.disabled {
        cursor: wait;
      }
    }

    > .error {
      position: absolute;
      font-size: 12px;
      line-height: 28px;
      color: @priceColor;
      i {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
  .agree {
    /* a {
      color: #069;
    } */
  }
}
.from-style1 {
  width: 358px;
  height: 44px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.inputplus {
  position: relative;
  left: -60px;
  top: 30px;
}
.code {
  position: absolute;
  right: 110px;
  top: 183px;
  text-align: center;
  line-height: 34px;
  font-size: 14px;
  color: #ce1200;
  width: 90px;
  height: 34px;
  cursor: pointer;
  background-color: #fff;
  border: none;
}
.code.disabled {
  color: grey;
}
.btn {
  background-color: #ce1200;
  height: 44px;
  font-size: 16px;
  width: 318px;
  border-radius: 5px;
  position: absolute;
  bottom: 120px;
  left: 100px;
  color: #fff;
  .el-button:focus,
  .el-button:hover {
    color: #ffffff;
    border-color: #ce1200;
    background-color: #ce1200;
    outline: 0;
  }
}
.form-item2 {
  margin: 134px 0 0 60px;
}
</style>
