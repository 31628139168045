<template>
  <div class="bck">
    <div class="app-header-sticky" :class="{ show: scrollTop > 78 }">
      <div :class="{ container1: scrollTop > 78 }">
        <HomeHeaderNew />
      </div>
    </div>
  </div>
</template>

<script setup>
// import AppHeaderNav from "@/components/AppHeaderNav";
// import AppHeaderNavCopy from "@/components/AppHeaderNavCopy";
import useScrollTop from "@/hooks/useScrollTops";
import HomeHeaderNew from "./HomeHeaderNew";

const scrollTop = useScrollTop();
</script>

<style scoped lang="less">
.app-header {
  .container {
    display: flex;
    align-items: center;
    // width: 1920px;
  }
}
.container1 {
  //background: rgba(#000, 0.85);
}
</style>
